import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { FOOTER_STORAGE_CANADA } from '@/utils/constants';

import styles from './corparate.module.scss';

const CorporateSection = () => {
  const router = useRouter();
  return (
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 col-xs-12">
      <h6 className="links-title">
        <FormattedMessage id="footer.corporate.title" />
      </h6>
      <ul className="footer-links">
        <li>
          <Link href={`/${router.locale}/careers`} prefetch={false}>
            <a>
              <FormattedMessage id="footer.corporate.careers" />
            </a>
          </Link>
        </li>
        <li>
          <a
            className={styles.pointer}
            href={FOOTER_STORAGE_CANADA}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="footer.corporate.storage-vault" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CorporateSection;
